import { render, staticRenderFns } from "./relationContent.vue?vue&type=template&id=985f3c24&scoped=true&"
import script from "./relationContent.vue?vue&type=script&lang=js&"
export * from "./relationContent.vue?vue&type=script&lang=js&"
import style0 from "./relationContent.vue?vue&type=style&index=0&id=985f3c24&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "985f3c24",
  null
  
)

export default component.exports