<template>
    <van-skeleton title avatar :row="3" :loading="loading">
        <div class="info_main">
            <p v-html="info.details"></p>
        </div>
    </van-skeleton>
</template>

<script>
import { 
    Skeleton
} from "vant";
import {
    getbiddingInfo
} from '@/api/api'
export default {
    // name:'招标公告详情',
    components: {
        [Skeleton.name]:Skeleton
    },
    computed:{
        id() {
            return this.$route.query.id;
        }
    },
    data() {
        return {
            loading:false,
            info:{}
        }
    },
    watch:{
        'id':{
            handler(val) {
                console.log('========id',val)
                this.getInfo(val);
            },
            immediate: true
        }
    },
    created() {},
    methods:{
        getInfo(id) {
            this.loading = true;
            getbiddingInfo({id:id}).then(res => {
                if(res.success) {
                    this.info = res.result;
                }else{
                    Toast.fail(res.message);
                }
            }).finally(() => {
                this.loading = false
            })
        }
    }
    
}
</script>

<style scoped lang="less">
.info_main {
    /deep/table {width: 90% !important;margin: 20px auto !important;}
}
</style>