<template>
    <div>
        <ContractInfo v-if="active=='contractInfo'" :contractId="contractId" @setCust="setCust"></ContractInfo>
        <CustomerInfo v-if="active=='customerInfo'" :custInfo="custInfo"></CustomerInfo>
        <van-tabbar  placeholder safe-area-inset-bottom v-model="active">
            <van-tabbar-item icon="home-o" name="contractInfo">合同信息</van-tabbar-item>
            <van-tabbar-item icon="friends-o" name="customerInfo">客户信息</van-tabbar-item>
        </van-tabbar> 
    </div>
</template>

<script>
import ContractInfo from './contractInfo'
import CustomerInfo from './customerInfo'
import { 
    Tabbar, TabbarItem
} from 'vant';
export default {
    components:{
        [Tabbar.name]:Tabbar,
        [TabbarItem.name]:TabbarItem,
        ContractInfo,
        CustomerInfo
    },
    data() {
        return {
            active:'contractInfo',
            contractId:'',
            custInfo:{}
        }
    },
    computed:{
        reQuery() {
            return this.$route.query
        }
    },
    watch:{
        'reQuery':{
            handler(val) {
                if(val) {
                    console.log('=========主页',val);
                    this.contractId = val.id;
                }
            },
            immediate:true
        }
    },
    methods:{
        setCust(obj) {
            this.custInfo = obj;
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.van-index-bar__sidebar {opacity: 0;}
</style>