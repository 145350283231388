<template>
    <!-- <j-form-container :disabled="disableSubmit"> -->
    <a-spin :spinning="confirmLoading">
        <h3 class="title">客户信息</h3>
        <van-index-bar :index-list="indexList" class="content">
            <van-index-anchor index="1">一、基本信息</van-index-anchor>
            <van-cell title="业主名称" :value="model.name"></van-cell>
            <van-cell title="集团公司" :value="model.parentname"></van-cell>
            <van-cell title="业主编号" :value="model.serialnumber"></van-cell>
            <van-cell title="业主简称" :value="model.shortname"></van-cell>
            <!-- <van-cell title="主要联系人" :value="model.linkuser"></van-cell>
            <van-cell title="主要联系人电话" :value="model.linktelphone"></van-cell> -->
            <van-cell title="所属行业" :value="model.industry"></van-cell>
            <van-cell title="是否重点客户" :value="model.isimportantcustomer"></van-cell>
            <van-cell title="法人代表" :value="model.juridicalperson"></van-cell>
            <van-cell title="业主类别">
                <template #default>
                    <span class="custom-title">
                        {{model.ownerclass=='CT'?'城投'
                        :model.ownerclass=='QT'?'其他'
                        :model.ownerclass=='SJY'?'设计院'
                        :model.ownerclass=='ZF'?'政府'
                        :model.ownerclass}}
                    </span>
                </template>
            </van-cell>
            <van-cell title="所在国家" :value="model.contracttype"></van-cell>
            <van-cell title="所在省份" :value="model.province"></van-cell>
            <van-cell title="所在城市" :value="model.city"></van-cell>
            <van-cell title="区县" :value="model.county"></van-cell>
            <van-cell title="乡镇" :value="model.township"></van-cell>
            <van-cell title="邮政编码" :value="model.zipcode"></van-cell>
            <van-cell title="单位地址" :value="model.address"></van-cell>
            <!-- <van-cell title="首次合作日期" :value="model.businessdate"></van-cell>
            <van-cell title="拜访周期" :value="model.visitcycle==0?'不拜访':'拜访'"></van-cell>
            <van-cell title="商务责任人" :value="model.businessdirectorname"></van-cell>
            <van-cell title="分子公司经营领导" :value="model.businessleadername"></van-cell>
            <van-cell title="责任分子公司" :value="model.branchcompanyname"></van-cell>
            <van-cell title="创建人" :value="model.createusername"></van-cell>
            <van-cell title="创建人部门" :value="model.createuserorgname"></van-cell>
            <van-cell title="创建时间" :value="model.createdate"></van-cell> -->


            <!-- <van-index-anchor index="2">二、财务信息</van-index-anchor>
            <van-cell title="开票单位名称" :value="model.invoicename"></van-cell>
            <van-cell title="税号" :value="model.taxaccounts"></van-cell>
            <van-cell>
                <van-collapse v-model="activeNames">
                    <van-collapse-item name="1" title="相关表信息">
                        <ul v-if="model.customerBank.length">
                            <li class="enginee" v-for="(item) in model.customerBank" :key="item.id">
                                <div class="enginee_msg">
                                    <p><span>开户银行</span><span>{{item.bank}}</span></p>
                                    <p><span>银行账号</span><span>{{item.bankaccount}}</span></p>
                                    <p><span>账户名称</span><span>{{item.bankaccountname}}</span></p>
                                    <p><span>电话</span><span>{{item.linkphone}}</span></p>
                                    <p><span>地址</span><span>{{item.bankinfo}}</span></p>
                                    <p><span>是否默认</span><span>{{item.defaultbank==1?'是':item.defaultbank}}</span></p>
                                </div>
                            </li>
                            <li></li>
                        </ul>
                        <p v-else style="text-align:center;">暂无数据</p>
                    </van-collapse-item>
                </van-collapse>
            </van-cell>

            <van-index-anchor index="2">三、其他信息</van-index-anchor>
            <van-cell title="备注" :value="model.remark"></van-cell> -->
            
        </van-index-bar>
    </a-spin>
    <!-- </j-form-container> -->
</template>

<script>
import { 
    IndexBar, IndexAnchor,
    Cell, CellGroup,
    Collapse, CollapseItem,
    // List,
    // PullRefresh
} from 'vant';
import {
    getCustomerInfo
} from '@/api/api'
// import {mapGetters} from 'vuex';
export default {
    components:{
        [IndexBar.name]:IndexBar,
        [IndexAnchor.name]:IndexAnchor,
        [Cell.name]:Cell,
        [CellGroup.name]:CellGroup,
        [Collapse.name]:Collapse,
        [CollapseItem.name]:CollapseItem,
        // [List.name]:List,
        // [PullRefresh.name]:PullRefresh,
    },
    props:{
        custInfo:{
            type:Object,
            default:() => {}
        }
    },
    data() {
        return {
            confirmLoading:false,
            indexList:[1, 2, 3, 4, 5, 6, 7],
            model:{},
            //所属工程
            activeNames:['1'],
        }
    },
    computed:{
        cust_info() {
            // {partya:'ac860167-7872-4e21-95be-f1a7f5073331'} || 
            return this.custInfo
        },
    },
    watch:{
        'cust_info':{
            handler(val) {
                if(val) {
                    console.log('==========客户id',val.partya)
                    this.initInfo(val.partya);
                }
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        //初始化合同详情
        initInfo(id) {
            this.confirmLoading = true;
            getCustomerInfo({id}).then(res =>{
                if(res.success) {
                    this.model = Object.assign({},res.result.customer);
                    this.model.customerBank = res.result.customerBank;
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            }).finally(() => {
                this.confirmLoading = false;
            })
        },
    }
}
</script>

<style lang="less" scoped>
// .box{position: relative;}
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
/deep/.van-collapse-item__content{padding: 10px 0;}
/deep/.van-index-anchor--sticky{position: fixed !important;}
.enginee{
    background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .enginee_msg{
        margin: 5px 0;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        flex-direction: column;
        flex: 1;
        >p{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }
    }
}
</style>