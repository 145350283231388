<template>
    <a-spin :spinning="confirmLoading">
    <div class="box">
        <h3 class="title">合同详情</h3>
        <van-index-bar :index-list="indexList" class="content">
            <van-index-anchor index="1">一、基本信息</van-index-anchor>
            <van-cell>
                <template #title>
                    <span class="custom-title">项目类型</span>
                </template>
                <template #label>
                    <span class="custom-title">(注:投标项目需要该项目进行了投标备案并且登记了投标结果后才能选择！)</span>
                </template>
                <template #default>
                    <span class="custom-title">{{typeof parseInt(model.projecttype) === 'number' && !isNaN(parseInt(model.projecttype)) ? (model.projecttype=='0'?'委托项目':'投标项目'):model.projecttype}}</span>
                </template>
            </van-cell>
            <van-cell title="已立项经营项目" :value="model.marketprojectname"></van-cell>
            <van-cell title="甲方" :value="model.partyaname"></van-cell>
            <van-cell title="乙方" :value="model.partybname"></van-cell>
            <van-cell title="丙方（分院）" :value="model.partycname"></van-cell>
            <van-cell title="实际付款单位" :value="model.realpayunitname"></van-cell>
            <van-cell title="合同名称" :value="model.name"></van-cell>
            <van-cell title="关联主合同" :value="model.maincontractname"></van-cell>
            <!-- <van-cell title="合同编号" :value="model.serialnumber"></van-cell> -->
            <van-cell title="合同性质" :value="model.contractclass"></van-cell>
            <van-cell title="合同类型" :value="contract_type[model.contracttype]"></van-cell>
            <van-cell title="省份" :value="model.province"></van-cell>
            <van-cell title="城市" :value="model.city"></van-cell>
            <van-cell title="区县" :value="model.county"></van-cell>
            <van-cell title="乡镇" :value="model.township"></van-cell>
            <!-- <van-cell title="签约日期" :value="model.signdate"></van-cell> -->
            <van-cell title="签约地点" :value="model.signaddress"></van-cell>
            <!-- <van-cell title="合同状态">
                <template #default>
                    <span class="custom-title">
                        {{model.contractstate=='Regist'?'待评审'
                        :model.contractstate=='Sign'?'履行'
                        :model.contractstate=='Finish'?'结束'
                        :model.contractstate=='Terminate'?'终止'
                        :model.contractstate}}
                    </span>
                </template>
            </van-cell> -->
            <!-- <van-cell title="合同起始日期" :value="model.startdate"></van-cell>
            <van-cell title="合同截止日期" :value="model.enddate"></van-cell> -->
            <!-- <van-cell title="仲裁单位" :value="model.zctype"></van-cell> -->
            <van-cell title="合同文本状态" :value="model.contracttextstate"></van-cell>
            <van-cell title="合同寄出人" :value="model.contracttextsendusername"></van-cell>
            <van-cell title="联系方式" :value="model.linkphone"></van-cell>
            <van-cell title="业务类型" :value="business_type[model.businesstype]"></van-cell>
            <van-cell title="项目规模">
                <template #default>
                    <span class="custom-title" v-if="model.projectlevel=='10' || model.projectlevel=='小型'">小型</span>
                    <span class="custom-title" v-if="model.projectlevel=='20' || model.projectlevel=='中型'">中型</span>
                    <span class="custom-title" v-if="model.projectlevel=='30' || model.projectlevel=='大型'">大型</span>
                </template>
            </van-cell>
            <van-cell title="合同签订人" :value="model.contractsignusername"></van-cell>
            <!-- <van-cell title="管理费费率" :value="model.glffl?model.glffl*100+'%':model.glffl"></van-cell>
            <van-cell title="合约类别" :value="model.hylb"></van-cell> -->
            <van-cell title="合同签约地点" :value="model.contractsignaddress"></van-cell>
            <!-- <van-cell title="主设计师" :value="model.maindesignusername"></van-cell>
            <van-cell title="项目负责人" :value="model.projectchargeusername"></van-cell>
            <van-cell title="商务负责人" :value="model.businessmanageridname"></van-cell> -->
            <van-cell title="生效合同原件寄回总院存档日期" :value="model.backdate"></van-cell>
            <van-cell title="行业代码" :value="model.industrycode"></van-cell>
            <van-cell title="总投资额" :value="model.ztze"></van-cell>
            <van-cell title="发票类型" :value="model.invoicetype"></van-cell>
            <van-cell title="EPC合同额" :value="model.epccontractvalue"></van-cell>
            <van-cell title="构造物" :value="model.aproject"></van-cell>

            
            <van-index-anchor index="2">二、金额信息</van-index-anchor>
            <van-cell title="合同金额（元）" :value="model.contractvalue"></van-cell>
            <van-cell title="币种">
                <template #default>
                    <span class="custom-title">{{model.contractcurrency=='RMB'?'人民币':'其他'}}</span>
                </template>
            </van-cell>
            <van-cell title="签约时汇率" :value="model.exchangerate"></van-cell>
            <van-cell title="合同结算金额（元）" :value="model.contractsettlementvalue"></van-cell>
            <van-cell title="折合人民币金额（元）" :value="model.thiscontractrmbamount"></van-cell>
            <van-cell title="人民币金额大写" :value="model.thiscontractrmbamountdx"></van-cell>
            <van-cell title="合同金额大写" :value="model.contractvaluedx"></van-cell>
            <van-cell title="结算人民币金额（元）" :value="model.contractrmbamount"></van-cell>
            <van-cell title="结算人民币大写" :value="model.contractrmbvaluedx"></van-cell>
            <!-- <van-cell title="税率" :value="model.taxrate?model.taxrate*100+'%':model.taxrate"></van-cell> -->
            <!-- <van-cell title="税金（元）" :value="model.taxvalue"></van-cell> -->
            <!-- <van-cell title="不含税金额（元）" :value="model.clearcontractvalue"></van-cell> -->


            <van-index-anchor index="2">三、补充协议</van-index-anchor>
            <van-cell v-if="supplements.length">
                <van-collapse v-model="activeNames" :border="false">
                    <van-collapse-item  v-for="(item,index) in supplements" :key="item.id"
                        :name="index+1+''" :title="item.name">
                        <ul v-if="supplements.length">
                            <li class="enginee">
                                <div class="enginee_msg">
                                    <van-cell title="补充协议名称" :value="item.name"></van-cell>
                                    <van-cell title="编号" :value="item.serialnumber"></van-cell>
                                    <van-cell title="金额（元）" :value="item.supplementaryvalue"></van-cell>
                                    <van-cell title="生效日期" :value="item.signdate"></van-cell>
                                    <van-cell title="经办人" :value="item.chargeusername"></van-cell>
                                    <van-cell>
                                        <template #title>
                                            <span class="custom-title">相关资料</span>
                                        </template>
                                        <template #default>
                                            <a style="display:block;margin:5px 0;"
                                                v-for="url of item.attachment.split(',')" :key="url"
                                                :href="url" rel="noopener noreferrer">
                                                {{url}}
                                            </a>
                                        </template>
                                    </van-cell>
                                    <!-- <p><span>补充协议名称</span><span>{{item.name}}</span></p>
                                    <p><span>编号</span><span>{{item.serialnumber}}</span></p>
                                    <p><span>金额(元)</span><span>{{item.supplementaryvalue}}</span></p>
                                    <p><span>生效日期</span><span>{{item.signdate}}</span></p>
                                    <p><span>经办人</span><span>{{item.chargeusername}}</span></p>
                                    <p>
                                        <span>相关资料</span>
                                        <span>
                                            <a style="display:block;" rel="noopener noreferrer" 
                                            v-for="url in item.attachment.split(',')" :key="url"
                                            :href="url" >{{url}}</a>
                                        </span>
                                    </p> -->
                                </div>
                            </li>
                        </ul>
                        <p v-else style="text-align:center;">暂无数据</p>
                    </van-collapse-item>
                </van-collapse>
            </van-cell>
            
            <!-- <van-index-anchor index="2">五、分包合同信息</van-index-anchor>
            <van-cell title="甲方法人" :value="model.partyalegalperson"></van-cell>
            <van-cell title="甲方法人" :value="model.partyalegalperson"></van-cell>
            <van-cell title="甲方法人" :value="model.partyalegalperson"></van-cell> -->


            <!-- <van-index-anchor index="2">四、相关方及人员</van-index-anchor>
            <van-cell title="甲方法人" :value="model.partyalegalperson"></van-cell>
            <van-cell title="甲方委托代理人" :value="model.partyahusbandingagentname"></van-cell>
            <van-cell title="乙方法人" :value="model.partyblegalperson"></van-cell>
            <van-cell title="乙方委托代理人" :value="model.partychusbandingagentname"></van-cell>
            <van-cell title="丙方法人" :value="model.partyclegalperson"></van-cell>
            <van-cell title="丙方委托代理人" :value="model.partychusbandingagentname"></van-cell>
            <van-cell title="商务责任部门" :value="model.businessdeptname"></van-cell>
            <van-cell title="商务经理" :value="model.businessmanagername"></van-cell>
            <van-cell title="分子公司" :value="model.productiondeptname"></van-cell>
            <van-cell title="生产责任人" :value="model.productionmanagername"></van-cell> -->



            <!-- <van-index-anchor index="2">七、收款项信息</van-index-anchor>
            <van-cell>
                <van-collapse v-model="activeNames">
                    <van-collapse-item name="3" title="列表详情">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="没有更多了"
                                @load="onLoad">
                                <ul>
                                    <li class="enginee">
                                        <h4>1.</h4>
                                        <div class="enginee_msg">
                                            <p><span>收款项名称</span><span>xx</span></p>
                                            <p><span>占比%</span><span>xx</span></p>
                                            <p><span>原币(收款项)</span><span>xx</span></p>
                                            <p><span>人民币(收款项)</span><span>xx</span></p>
                                            <p><span>原币(已开票)</span><span>xx</span></p>
                                            <p><span>人民币(已开票)</span><span>xx</span></p>
                                            <p><span>原币(已收款)</span><span>xx</span></p>
                                            <p><span>人民币(已收款)</span><span>xx</span></p>
                                            <p><span>原计划日期</span><span>xx</span></p>
                                            <p><span>计划日期</span><span>xx</span></p>
                                            <p><span>关联项目</span><span>xx</span></p>
                                            <p><span>名称(关联里程碑)</span><span>xx</span></p>
                                            <p><span>计划完成日期(关联里程碑)</span><span>xx</span></p>
                                            <p><span>实际完成日期(关联里程碑)</span><span>xx</span></p>
                                        </div>
                                    </li>
                                </ul>
                            </van-list>
                        </van-pull-refresh>
                    </van-collapse-item>
                </van-collapse>
            </van-cell> -->

            <van-index-anchor index="2">四、其他信息</van-index-anchor>
            <van-cell title="备注" :value="model.remark"></van-cell>
            
            <!-- <van-index-anchor index="2">八、合同文本</van-index-anchor>
            <van-cell>
                <template #title>
                    <span class="custom-title">合同文本</span>
                </template>
                <template #label>
                    <a style="display:block;margin:5px 0;"
                        href="checkObj.pdf" rel="noopener noreferrer">
                        {{model.contractattachment}}
                    </a>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">合同正式附件</span>
                </template>
                <template #label>
                    <a style="display:block;margin:5px 0;"
                        href="checkObj.pdf" rel="noopener noreferrer">
                        {{model.contractfj}}
                    </a>
                </template>
            </van-cell>
            <van-cell>
                <template #title>
                    <span class="custom-title">相关资料</span>
                </template>
                <template #label>
                    <a style="display:block;margin:5px 0;"
                        href="checkObj.pdf" rel="noopener noreferrer">
                        {{model.attachment}}
                    </a>
                </template>
            </van-cell> -->


                <!-- <van-cell>
                    <template #title>
                        <span class="custom-title">电子合约</span>
                    </template>
                    <template #label>
                        <a style="display:block;margin:5px 0;"
                            href="checkObj.pdf" rel="noopener noreferrer">
                            {{model.mainfileid}}
                        </a>
                    </template>
                </van-cell>
                <van-cell>
                    <template #title>
                        <span class="custom-title">电子合约PDF</span>
                    </template>
                    <template #label>
                        <a style="display:block;margin:5px 0;"
                            href="checkObj.pdf" rel="noopener noreferrer">
                            {{model.mainpdf}}
                        </a>
                    </template>
                </van-cell> -->
                <van-cell>
                    <template #title>
                        <span class="custom-title">合同附件</span>
                    </template>
                    <template #default>
                        <a style="display:block;margin:5px 0;"
                            :href="model.pdfFileUrl" rel="noopener noreferrer">
                            <!-- {{model.pdfFileUrl}} -->
                            PDF下载预览
                        </a>
                    </template>
                </van-cell>
            
        </van-index-bar>
    </div>
    </a-spin>
</template>

<script>
import { 
    IndexBar, IndexAnchor,
    Cell, CellGroup,
    Collapse, CollapseItem,
    List,
    PullRefresh
} from 'vant';
import {
    getContractInfo,
    getContractSupplement,
} from '@/api/api'
import {contract_type,business_type} from '@/utils/customExpression'
export default {
    components:{
        [IndexBar.name]:IndexBar,
        [IndexAnchor.name]:IndexAnchor,
        [Cell.name]:Cell,
        [CellGroup.name]:CellGroup,
        [Collapse.name]:Collapse,
        [CollapseItem.name]:CollapseItem,
        [List.name]:List,
        [PullRefresh.name]:PullRefresh,
    },
    props:{
        contractId:{
            type:String,
            default:''
        }
    },
    data() {
        return {
            confirmLoading:false,
            indexList:[1, 2, 3, 4, 5, 6],
            model:{},
            //所属工程
            activeNames:['1'],
            // pagination:{
            //     pageNo:1,
            //     pageSize:10
            // },
            // list: [],
            // loading: false,
            // finished: false,
            // refreshing: false,
            //数据字典
            business_type,//业务类型
            contract_type,//合同类型
            supplements:[],//补充协议
        }
    },
    computed:{
        contract_id() {
            return this.contractId
        }
    },
    watch:{
        'contract_id':{
            handler(val) {
                if(val) {
                    console.log('=======合同id',val)
                    this.initInfo(val);
                    this.initSupplement(val);
                }
            },
            immediate:true,
            deep:true
        }
    },
    mounted() {},
    methods:{
        //初始化合同详情
        initInfo(id) {
            this.confirmLoading = true;
            getContractInfo({id}).then(res =>{
                if(res.success) {
                    this.model = Object.assign({},res.result);
                    this.$emit('setCust',{
                        partya:res.result.partya,
                        partyaname:res.result.partyaname
                    })
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            }).finally(() => {
                this.confirmLoading = false;
            })
        },
        // 所属工程
        initSupplement(id) {
            getContractSupplement({id}).then(res => {
                if(res.success) {
                    this.supplements = res.result;
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        // onRefresh() {
        //     // 清空列表数据
        //     this.finished = false;
        //     // 重新加载数据
        //     // 将 loading 设置为 true，表示处于加载状态
        //     this.loading = true;
        //     this.onLoad();
        // },
    }
}
</script>

<style lang="less" scoped>
// .box{position: relative;}
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
/deep/.van-index-anchor--sticky{position: fixed !important;}
.enginee{
    // background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
    .enginee_msg{
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        // padding: 0 10px;
        flex-direction: column;
        flex: 1;
        // >p{
        //     display: flex;
        //     justify-content: space-between;
        // }
    }
}
</style>