<template>
    <a-spin tip="Loading..." :spinning="spinning">
        <header class="seal_heard">
            <!-- <span class="back">返回</span> -->
            <span class="title">{{reQuery.name}}</span>
            <a-button class="btn" :loading="loading" type="primary" @click="handlerSeal">盖章</a-button>
        </header>
        <div class="main">
            <onlyoffice></onlyoffice>
        </div>
    </a-spin>
</template>

<script>
import onlyoffice from '@/components/onlyoffice/onlyoffice'
import {
    transformContactToPdf,
} from '@/api/api'

export default {
    components:{onlyoffice},
    data() {
        return {
            spinning:false,
            loading:false
        }
    },
    computed:{
        reQuery() {
            return this.$route.query
        }
    },
    methods:{
        //盖章
        handlerSeal() {
            this.transformContact();
        },
        //转换word-to-pdf
        transformContact() {
            transformContactToPdf({
                contractId:this.reQuery.contract_id
            }).then(res => {
                if(res.success){
                    this.$router.push({
                        path: '/businessPlatform/seal',
                        query: {
                            pdfs:res.result,
                            contractId:this.reQuery.contract_id
                        }
                    })
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .title{flex:1;text-align: center;}
    .btn{margin-right: 30px;}
}
.main {
    height: calc(100vh - 50px);
}
</style>